import { Component, OnInit, ElementRef, AfterViewInit,TemplateRef, HostListener, Output, EventEmitter,ViewChild,ViewChildren,QueryList } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ServiceHeaderService } from "./_services/service-header.service";
import { LoaderService } from "../../shared/services/loader.service";
import { AuthJwtService } from "../../shared/services/auth-jwt.service";
import { LogoutService } from '../../shared/services/logout.service';
import { ConfirmAlertDeleteService } from '../../shared/services/confirm-alert-delete.service';
// import { FooterService } from '../footer/_service/footer.service';
import { Subscription} from 'rxjs';
// import { MapSalesService } from '../../admin/map-sales/map-sales.service';

import { CustomerService } from '../../admin/recruit-layout/_service/customer.service'; 

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  @ViewChild('upgradenow') upgradenow: TemplateRef<any>;
  @Output() toggleEvent = new EventEmitter;
  @Output() office = new EventEmitter();
  @ViewChildren('menuItem') menuItems: QueryList<ElementRef>;
  public currentPath = '';
  public resource: any;
  public customerList: any = [];
  public rawCustomerList: any = [];
  public stateListArray: any = [];
  public subscriptionListArray: any = [];
  public appointmentListArray: any = [];
  public customerDetails: any = [];
  public customerInput: string = '';
  public tempSeleCust: number = -1;
  public previousSearchCustomer = '';
  public employeeDetails: any;
  public officeListArray: any = [];
  public selectedOffice: any;
  public dataSub: Subscription;
  public notification: Subscription;
  public newAlertCount: number = 0;
  public newSoldCount: number = 0;
  public newServiceCount: number = 0;
  public newSaleCount: number = 0;
  public newCustomerCount: number = 0;
  public newProspectCount: number = 0;
  public permission: any = null;
  public modalIsUpgrade: boolean =(+sessionStorage.getItem('upgrade')==0 || sessionStorage.getItem('upgrade')==undefined || sessionStorage.getItem('upgrade')==null)
  pendingContractCount: any ;
  constructor(
    private elementRef: ElementRef,
    private route: ActivatedRoute,
    private _serviceHeaderService: ServiceHeaderService,
    private _loaderService: LoaderService,
    private _authJwtService: AuthJwtService,
    private _router: Router,
    private _logoutService: LogoutService,
    // public _footerService: FooterService,
    public _ConfirmAlertDeleteService: ConfirmAlertDeleteService,
    private modalService: BsModalService,
    private _hiringService: CustomerService,
  ) {

  }

  ngOnInit() {
    // pass count from pending contract component.
    this._hiringService.data$.subscribe(data => {
      if (data) {
        this.getPendingCount();
      } else {
        this.getPendingCount();
      }
    });

    this.getDetails();
    this._authJwtService.sub.subscribe(res => {
      const resp: any = res;
      this.permission = resp.data;
    })
    this._authJwtService.empdetails.subscribe(res => {
      this.employeeDetails = res;
      this.GetPermission();
    })
    this._authJwtService.callFunction.subscribe(res=>{
      if(res){
        this.modalIsUpgrade=false;
        if(this.currentPath=='payment-report')
        this._authJwtService.paymentReportUpdate.next(true);
      }
      this.getDetails();
    });
    this.GetPermission();
    this.getResourceDetails();
    this.officeList();

    this.dataSub = this._loaderService.castLoader.subscribe(loader => this.reLoader(loader));
    // this.getInitialAlert();
    // this.getAlartNotification();
    this.currentPath=this.route.root.children[0].children[0].url['_value'][0]['path'];
    // this.currentPath = this.route.root.url['_value'][0]['path'];    
    // this.getResourceDetails();
    this._router.events
      .subscribe(event => {
        
        let currentRoute = this.route.root;
        // while (currentRoute.children[0] !== undefined) {
        //   currentRoute = currentRoute.children[0];
        // }
        // this.currentPath = currentRoute.url['_value'][0]['path'];
        const temp=currentRoute.children[0].children[0].url['_value'][0]['path'];
        this.currentPath=temp!=undefined?temp:'';
        if (event instanceof NavigationEnd) {
          this.scrollToActiveMenuItem();
        }
        /* On Navigation Route Loader Is Enabled  */
        switch (true) {
          case event instanceof NavigationStart: {
            this._loaderService.showLoader();
            break;
          }
          case event instanceof NavigationCancel:
          case event instanceof NavigationError: {
            this._loaderService.hideLoader();
            break;
          }
          case event instanceof NavigationEnd: {
            break;
          }
          default: {
            break;
          }
        }
      });
    if (this.currentPath == '')
      this.currentPath = this.route.root.children[0].url['_value'][0]['path'];


    // get subscription datails

    // Initial scroll check in case the active route is loaded initially
 
  }
  scrollToActiveMenuItem() {
    this.menuItems.forEach(menuItem => {
      if (menuItem.nativeElement.classList.contains('active')) {
        menuItem.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    });
  }

  public get value1() {
    return this.selectedOffice;
  }

  GetPermission() {
    this._serviceHeaderService.GetPermissionFromServer().subscribe(res => {
      this.permission = res.data;
      this._authJwtService.permission_list=res.data;
      console.log(this._authJwtService.permission_list)
    });
  }


  ngAfterload() {
    var s3 = document.createElement("script");
    s3.type = "text/javascript";
    s3.src = "./assets/vendors/bower_components/bootstrap/dist/js/bootstrap.min.js";
    this.elementRef.nativeElement.appendChild(s3);

    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "./assets/dist/js/jquery.slimscroll.js";
    this.elementRef.nativeElement.appendChild(s);

    var s1 = document.createElement("script");
    s1.type = "text/javascript";
    s1.src = "./assets/dist/js/init.js";
    this.elementRef.nativeElement.appendChild(s1);

    var s2 = document.createElement("script");
    s2.type = "text/javascript";
    s2.src = "./assets/custom/RoutesPro.js";
    this.elementRef.nativeElement.appendChild(s2);
  }

  ngAfterViewInit() {
    this.ngAfterload();

  }

  /**
   * Method         : getResourceDetails
   * Description    : Resource Details
   * Date           : 2019-03-01
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public getResourceDetails() {
    if (localStorage.getItem('_token') !== null) {
      const rawToken = localStorage.getItem('_token');
      this.resource = this._authJwtService.decodeAuthToken(rawToken);
      this._serviceHeaderService.getEmployeeDetails(this.resource.id).subscribe((data) => {
        if (data.success == 1) {
          this.employeeDetails = data.employee_det;
        } else {

        }
      },
        (error) => {
          console.log(error);
        })
    }
  }

  officeListArray1 = [{ id: 25, name: 'corporate' }];
  /* @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    console.log(event);
    if(this.customerList.length > 0){
      if(event.code == "ArrowDown"){
        if(this.tempSeleCust < 14){
          this.tempSeleCust++;
          let elmnt = document.getElementById(`block_${this.tempSeleCust}`);
          elmnt.scrollIntoView();
        }
      }
      if(event.code == "ArrowUp"){
        if(this.tempSeleCust > 0){
          this.tempSeleCust--;
          let elmnt = document.getElementById(`block_${this.tempSeleCust}`);
          elmnt.scrollIntoView();
        }
        if(this.tempSeleCust == 0){
          this.tempSeleCust = 0;
          let elmnt = document.getElementById(`block_${this.tempSeleCust}`);
          elmnt.scrollIntoView();
        }
      } 
      if( event.code == 'NumpadEnter' || event.code == 'Enter'){
        let selectedCustomer = this.customerList[this.tempSeleCust];
        setTimeout(() => {
          this.customerList = [];
        }, 250);
        this.onSelectCustomer(selectedCustomer);
      }
    }
  }*/


  /**
   * Method         : onLocationFocus
   * Description    : On Focus search field
   * Date           : 2019-03-01
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public onLocationFocus() {
    this.tempSeleCust = 0;
    console.log('hi')
  }

  /**
   * Method         : onLocationBlur
   * Description    : On blur search field
   * Date           : 2019-03-01
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public onLocationBlur() {
    this.tempSeleCust = -1;
  }

  /**
   * Method         : onArrowUp
   * Description    : on arrow up scroll div
   * Date           : 2019-03-01
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public onArrowUp() {
    if (this.customerList.length > 0) {
      if (this.tempSeleCust > 0) {
        this.tempSeleCust--;
        let elmnt = document.getElementById(`block_${this.tempSeleCust}`);
        elmnt.scrollIntoView();
      }
      if (this.tempSeleCust == 0) {
        this.tempSeleCust = 0;
        let elmnt = document.getElementById(`block_${this.tempSeleCust}`);
        elmnt.scrollIntoView();
      }
    }
  }

  /**
   * Method         : onArrowDown
   * Description    : on Arrow Down scroll div
   * Date           : 2019-03-01
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public onArrowDown() {
    if (this.customerList.length > 0) {
      if (this.tempSeleCust < 14) {
        this.tempSeleCust++;
        let elmnt = document.getElementById(`block_${this.tempSeleCust}`);
        elmnt.scrollIntoView();
      }
    }
  }

  /**
 * Method         : onArrowUpsm
 * Description    : on arrow up scroll div
 * Date           : 2019-03-25
 * Author         : Satyabrata Rakshit(Appsbee)
 */
  public onArrowUpsm() {
    if (this.customerList.length > 0) {
      if (this.tempSeleCust > 0) {
        this.tempSeleCust--;
        let elmnt = document.getElementById(`blocksm_${this.tempSeleCust}`);
        elmnt.scrollIntoView();
      }
      if (this.tempSeleCust == 0) {
        this.tempSeleCust = 0;
        let elmnt = document.getElementById(`blocksm_${this.tempSeleCust}`);
        elmnt.scrollIntoView();
      }
    }
  }

  /**
   * Method         : onArrowDownsm
   * Description    : on Arrow Down scroll div
   * Date           : 2019-03-25
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public onArrowDownsm() {
    if (this.customerList.length > 0) {
      if (this.tempSeleCust < 14) {
        this.tempSeleCust++;
        let elmnt = document.getElementById(`blocksm_${this.tempSeleCust}`);
        elmnt.scrollIntoView();
      }
    }
  }

  /**
   * Method         : onSelectkeyB
   * Description    : on Select customer by keyboard
   * Date           : 2019-03-01
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public onSelectkeyB() {
    if (this.customerList.length > 0) {
      let selectedCustomer = this.customerList[this.tempSeleCust];
      setTimeout(() => {
        this.customerList = [];
        this.showmsg = false;
      }, 250);
      this.onSelectCustomer(selectedCustomer);
    }
  }

  /**
   * Method         : onKeyUp
   * Description    : Search customer on every kyeup
   * Date           : 2019-03-01
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  showmsg = false;
  onClickedOutside(e: Event) {
    this.showmsg = false;
    this.customerInput = '';
  }
  public onKeyUp(event) {

    let inputData = event.target.value;
    if (this.previousSearchCustomer !== inputData) {
      let splitedData = inputData.split('');
      if (splitedData.length > 1) {
        let params = {
          global_search: true,
          orderBy: [{ orderId: "id", dir: "desc" }],
          draw: 1,
          start: 0,
          length: 15,
          search_flag: 'global',
          search: {
            value: inputData
          },
        }
        this._serviceHeaderService.globalCustomerSearch(params).subscribe((resp) => {
          if (resp.success == 1) {
            this.rawCustomer(resp.data);
            this.rawCustomerList = resp.data;
            this.showmsg = true;
            this.previousSearchCustomer = inputData;
          } else {

          }
        },
          (error) => {
            console.log(error);
          });
      }
      else {
        this.customerList = [];
        this.showmsg = false;
        this.tempSeleCust = 0;
        this.previousSearchCustomer = '';
      }
    } else {

    }
  }

  /**
   * Method         : rawCustomer
   * Description    : assign to secounday customer array
   * Date           : 2019-03-01
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public rawCustomer(data) {
    this.customerList = [];
    data.forEach(elm => {
      this.customerList.push(elm)
    });
  }

  /**
   * Method         : closeCustomerSearch
   * Description    : on close customer list
   * Date           : 2019-03-01
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public closeCustomerSearch() {
    this.customerList = [];
    this.customerInput = '';
    this.tempSeleCust = 0;
    this.previousSearchCustomer = '';
    this.showmsg = false;
  }

  /**
   * Method         : onSelectCustomer
   * Description    : on Select Customer
   * Date           : 2019-03-01
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public onSelectCustomer(item) {
    // this._loaderService.showLoader();
    let id = item.office.id;
    this.tempSeleCust = 0;
    this.customerList = [];
    this.showmsg = false;
    this.customerInput = '';
    this.previousSearchCustomer = '';
    let params = {
      id: item.id,
      offChanged: false,
      type: 'editCustomer',
      customerDetails:item
    }
    if (localStorage.getItem('_usertoken') !== null) {
      let currentOffice = parseInt(atob(localStorage.getItem('_usertoken')));
      if (currentOffice == item.office.id) {
        localStorage.setItem("custDetails", JSON.stringify(params));
        this.routetoCustomer();
      } else {
        let confirmMsg = confirm(`Are you want to switch ${item.office.office_name} Branch`);
        if (confirmMsg == true) {
          params.offChanged = true;
          localStorage.setItem("custDetails", JSON.stringify(params));
          localStorage.setItem("setoffice", id);
          this._loaderService.diffOfficeCustomer();
          this.routetoCustomer();
        } else {
          this._loaderService.hideLoader();
        }
      }
    } else {
      // params.offChanged = true;
      // localStorage.setItem("custDetails", JSON.stringify(params));
      // localStorage.setItem("setoffice", id);
      // this.routetoCustomer();
      // this._loaderService.diffOfficeCustomer();
      // this.getCustomerDetails(item.id, template);
    }
  }

  /**
   * Method         : routetoCustomer
   * Description    : route to customer page of specific branck
   * Date           : 2019-03-01
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public routetoCustomer() {
    const currentPath = location.hash.split('/').pop();
    if (currentPath == 'customers-list') {
      this._loaderService.customerDetail();
      // this._router.navigate(['customers/customers-list']);
    } else {
      this._router.navigate(['customers/customers-list']);
    }
  }

  /**
   * Method         : signOut
   * Description    : Sign Out from App
   * Date           : 2018-12-10
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public signOut() {
    // let conformation = confirm("do you want to sign out?");
    // if (conformation == true) {
    // this._logoutService.LogOut();
    // }
    this._ConfirmAlertDeleteService.confirmButton("Do you want to sign out?").subscribe(
      (resp) => {
        if (resp) {
          this._logoutService.LogOut();
        }
      }
    );
  }


  /**
   * Method         : officeList
   * Description    : get list of Office
   * Date           : 2018-12-10
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public officeList() {
    this.officeListArray = [];
    this._serviceHeaderService.getActiveOfficeList().subscribe((data) => {
      if (data.success == 1) {
        data.officelist.forEach(elm => {
          if (elm.is_active == '1') {
            this.officeListArray.push(elm);
          }
        });
        this.setOfficeList();
      } else {

      }
    },
      (error) => {
        console.log(error);
      });
  }

  // /**
  //  * Method         : getResourceDetails
  //  * Description    : Logged in resource Details
  //  * Date           : 2018-12-10
  //  * Author         : Satyabrata Rakshit(Appsbee)
  //  */
  // public getResourceDetails() {
  //   if (localStorage.getItem('_token') !== null) {
  //     const rawToken = localStorage.getItem('_token');
  //     this.resource = this._authJwtService.decodeAuthToken(rawToken);
  //   }
  // }

  /**
   * Method         : setOfficeList
   * Description    : set office List
   * Date           : 2018-12-10
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public setOfficeList() {
    if (this.resource.office_id !== null && this.resource.office_id !== undefined) {
      if (localStorage.getItem('_usertoken') !== null) {
        let OfficeId = parseInt(atob(localStorage.getItem('_usertoken')));
        if (OfficeId == 0) {
          this.setOfficeForZonalLogedInResources();
        } else {
          this.selectedOffice = OfficeId;
        }
      } else {
        this.officeListArray.forEach(elm => {
          if (this.resource.office_id == elm.id) {
            this.selectedOffice = elm.id;
          }
        });
      }
    } else {
      if (localStorage.getItem('_usertoken') !== null) {
        let OfficeId = parseInt(atob(localStorage.getItem('_usertoken')));
        if (OfficeId == 0) {
          this.setOfficeForZonalLogedInResources();
        } else {
          this.selectedOffice = OfficeId;

        }

      }

    }


    this.officeid = this.selectedOffice;
  }

  /**
   * Method         : onChange
   * Description    : on change office
   * Date           : 2019-01-31
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public onChange(event) {
    let officeID = btoa(event.id);
    this.changedOffice(event.id);
    this.onChangeOffice(officeID);

    this.officeid = event.id;
  }
  officeid: any;

  /**
   * onChangeOffice
   */
  public onChangeOffice(officeID) {
    if (localStorage.getItem('_usertoken') !== null) {
      localStorage.removeItem('_usertoken');
      localStorage.setItem("_usertoken", officeID);
    } else {
      localStorage.setItem("_usertoken", officeID);
    }
    // const currentPath = location.hash.split('/').pop();
    const currentPath = location.hash.split('#').pop()
    
    const currentPath1 = location.hash.split('/').pop()
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = 'reload';
    this._router.navigate([currentPath]);
    // if (currentPath1 == 'dashboard') {
    //   // this._router.navigate(['dashboard-layout/dashboard']);
    //   this._loaderService.reloadOfficeList();
    // } else {
    //   // this._router.navigate(['dashboard-layout/dashboard']);
    //   this.getInitialAlert();
    //   setTimeout(() => {
    //     console.log('call');
    //     this._loaderService.changeOffice();
    //   }, 800);
    // }
  }

  /**
   * Method         : changedOffice
   * Description    : set office name in local storage
   * Date           : 2019-01-31
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public changedOffice(id) {
    this.officeListArray.forEach(elm => {
      if (id == elm.id) {
        if (localStorage.getItem('_name') !== null) {
          localStorage.removeItem('_name');
          localStorage.setItem("_name", elm.office_name);
        } else {
          localStorage.setItem("_name", elm.office_name);
        }
      }
    });
  }

  /**
   * getAlartNotification
   */
  // public getAlartNotification() {
  //   this.notification = interval(5 * 60 * 1000).pipe(
  //     concatMap(() => this._footerService.alertCount())
  //   ).subscribe(
  //     (resp) => {
  //       if (resp.success == 1) {
  //         this.newSoldCount = resp.data;
  //         this.newServiceCount = resp.total_unread_msg_count.servicepro_unread_msg_count;
  //         this.newSaleCount = resp.total_unread_msg_count.salesrep_unread_msg_count;
  //         this.newCustomerCount = resp.total_unread_msg_count.customer_unread_msg_count;
  //         this.newProspectCount = resp.total_unread_msg_count.lead_unread_msg_count;
  //         this.newAlertCount = this.newSoldCount + this.newServiceCount + this.newSaleCount + this.newCustomerCount + this.newProspectCount;
  //       } else {

  //       }
  //     },
  //     (error) => {
  //       this.notification.unsubscribe();
  //     }
  //   );
  // }

  /**
   * getInitialAlert
   */
  public getInitialAlert() {
    this._serviceHeaderService.alertCount().subscribe(
      (resp) => {
        if (resp.success == 1) {
          this.newSoldCount = resp.data;
          this.newServiceCount = resp.total_unread_msg_count.servicepro_unread_msg_count;
          this.newSaleCount = resp.total_unread_msg_count.salesrep_unread_msg_count;
          this.newCustomerCount = resp.total_unread_msg_count.customer_unread_msg_count;
          this.newProspectCount = resp.total_unread_msg_count.lead_unread_msg_count;
          this.newAlertCount = this.newSoldCount + this.newServiceCount + this.newSaleCount + this.newCustomerCount + this.newProspectCount;
        } else {

        }
      }
    );
  }

  /**
   * Method         : reLoader
   * Description    : reload for any new data field when any office related date changes
   * Date           : 2019-01-22
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public reLoader(data) {
    if (data === 'reloadOfficeList') {
      this.officeList();
      // this.getInitialAlert();
    }
    if (data === 'diffOfficeCustomer') {
      if (localStorage.getItem('setoffice') !== null) {
        let officeID = btoa(localStorage.getItem('setoffice'));
        this.selectedOffice = parseInt(localStorage.getItem('setoffice'));
        /* ======= */
        if (localStorage.getItem('_usertoken') !== null) {
          localStorage.removeItem('_usertoken');
          localStorage.setItem("_usertoken", officeID);
        } else {
          localStorage.setItem("_usertoken", officeID);
        }
        /* ======== */
        this.changedOffice(localStorage.getItem('setoffice'));
        localStorage.removeItem('setoffice');
      }
      // this.getInitialAlert();
    }
  }

  setOfficeForZonalLogedInResources = () => {
    if (this.officeListArray.length > 0) {
      this.selectedOffice = this.officeListArray[0].id;
      let officeID = btoa(this.selectedOffice);
      this.changedOffice(this.selectedOffice);
      this.onChangeOffice(officeID)
    }
  }

  onClickAlertNotificationCount = (index: number) => {
    const currentPath = location.hash.split('/').pop();
    localStorage.setItem('alertNotificationTab', index.toString())
    switch (currentPath) {
      case 'messages':
        this._loaderService.changeAlertNotificationTab();
        break;
      default:
        this._router.navigate(['notifications/messages']);
        break;
    }
  }


  toggleEnabled = false;
  navToggle() {
    this.toggleEnabled = !this.toggleEnabled;
    this.toggleEvent.emit(this.toggleEnabled);
    if(this.currentPath=='dashboard' || this.currentPath=='dashboard-layout')
    setTimeout(() => {
      this._authJwtService.chartResize.next(null);
    }, 400);
  }


  // subscription details
  subscription_days = 0;
  subscription_error = '';
  subscription_msg='';
  is_upgrade:any=null;
  getSubscriptionDetails() {
    this._authJwtService.subscriptionDetails().subscribe({
      next: (result) => {
        
        if (result.status == 1) {
          this.subscription_days = result.expire_in;
          this.subscription_error = '';
          this.subscription_msg=result.expire_msg;
          this.is_upgrade=result.is_upgrade;
          if(this.modalIsUpgrade)
          this.UpgradeModal(this.upgradenow);
        }
      },
      error: (err) => {
        console.log(err);
        this.subscription_error = 'error';
      }
    })
  }
  ngOnDestroy() {
    this.dataSub.unsubscribe();
    this.notification.unsubscribe();
  }


  modalRef: BsModalRef;
  UpgradeModal(template: TemplateRef<any>){
    this._loaderService.showLoader();
    const config = {
      backdrop: true,
      ignoreBackdropClick: true
    };
    this._serviceHeaderService.getSubscriptionDetails().subscribe(res=>{

      if(res.status==1){
          
          this._authJwtService.shared=res;
          this.modalRef = this.modalService.show(template, Object.assign(config,{ class: 'gray modal-md' }));
          if(res.company_permission_list!=null){
            this.tracking_access=res.company_permission_list.customer_report.is_access;
            this.customer_report_access=res.company_permission_list.tracking.is_access
          }
      }
      else{
        this._loaderService.hideLoader();
      }
  },err=>{
    this._loaderService.hideLoader();
  });
    
  }


  SubsData:any;
  tracking_access:any=null;
  customer_report_access:any=null;
  // public static permission_on_subs:any='';
  getDetails(){
      this._serviceHeaderService.getSubscriptionDetails().subscribe(res=>{

          if(res.status==1){
              // this.permission_on_subs=res;
              
              this._authJwtService.shared=res;
              if(res.company_permission_list!=null){
                this.tracking_access=res.company_permission_list.customer_report.is_access;
                this.customer_report_access=res.company_permission_list.tracking.is_access
              }
              if(this.currentPath=='office-list')
              this._authJwtService.officeUpdate.next(res.company_permission_list.office);
              else if(this.currentPath=='list'){
                this._authJwtService.employeeUpdate.next(res.company_permission_list.employee_addition_limit);
              }
              

              this.getSubscriptionDetails();
          }
          else{

          }
      });
  }

  getPendingCount(){
    this._hiringService.getPendingCount().subscribe(res => {
      if(res.status == 1){
        this.pendingContractCount = res.pendingContractListCount;
      }else{
        this.pendingContractCount = 0;
      }
    });
  }
  scrollToActive(){
    const element = document.querySelector(`.active`);
    if (element && !this.isElementInViewport(element)) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
  isElementInViewport(element: Element): boolean {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }
}
